var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [_c("v-card-title", [_vm._v("Domain Omit Management")])],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                { staticClass: "ml-2" },
                [
                  _c("v-data-table", {
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.domains,
                      "disable-pagination": "",
                      "hide-default-footer": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "top",
                        fn: function() {
                          return [
                            _c(
                              "v-toolbar",
                              { attrs: { flat: "" } },
                              [
                                _c("v-toolbar-title", [_vm._v("Domains")]),
                                _c("v-divider", {
                                  staticClass: "mx-4",
                                  attrs: { inset: "", vertical: "" }
                                }),
                                _c(
                                  "v-form",
                                  {
                                    on: {
                                      submit: function($event) {
                                        $event.preventDefault()
                                        return _vm.searchDomainOmits.apply(
                                          null,
                                          arguments
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.domainSearchValidation,
                                      callback: function($$v) {
                                        _vm.domainSearchValidation = $$v
                                      },
                                      expression: "domainSearchValidation"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              "align-self": "center",
                                              cols: "11"
                                            }
                                          },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                dense: "",
                                                clearable: "",
                                                "single-line": "",
                                                "hide-details": "",
                                                loading: _vm.isLoadingDomains,
                                                label: "Domain",
                                                maxlength: "50",
                                                rules: [
                                                  _vm.rules.required,
                                                  _vm.rules.validDomain
                                                ]
                                              },
                                              model: {
                                                value: _vm.domainSearch,
                                                callback: function($$v) {
                                                  _vm.domainSearch = $$v
                                                },
                                                expression: "domainSearch"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "1" } },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "ma-2",
                                                attrs: {
                                                  color: "primary",
                                                  type: "submit",
                                                  disabled: !_vm.domainSearchValidation
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { dark: "" } },
                                                  [
                                                    _vm._v(
                                                      "\n                        mdi-magnify\n                      "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c("v-spacer"),
                                _c(
                                  "v-dialog",
                                  {
                                    attrs: { "max-width": "500px" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "mb-2",
                                                    attrs: {
                                                      color: "primary",
                                                      dark: ""
                                                    }
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  "\n                    Add Domain\n                  "
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ]),
                                    model: {
                                      value: _vm.dialog,
                                      callback: function($$v) {
                                        _vm.dialog = $$v
                                      },
                                      expression: "dialog"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-form",
                                      {
                                        model: {
                                          value: _vm.newDomainValid,
                                          callback: function($$v) {
                                            _vm.newDomainValid = $$v
                                          },
                                          expression: "newDomainValid"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-card",
                                          [
                                            _c("v-card-title", [
                                              _c(
                                                "span",
                                                { staticClass: "text-h5" },
                                                [_vm._v("Add Domain")]
                                              )
                                            ]),
                                            _c(
                                              "v-card-text",
                                              [
                                                _c(
                                                  "v-container",
                                                  [
                                                    _c(
                                                      "v-row",
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12"
                                                            }
                                                          },
                                                          [
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                label:
                                                                  "New Domain",
                                                                maxlength: "50",
                                                                rules: [
                                                                  _vm.rules
                                                                    .required,
                                                                  _vm.rules
                                                                    .validDomain
                                                                ],
                                                                hint:
                                                                  _vm.addDomainError,
                                                                "persistent-hint": !!_vm.addDomainError
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.newDomainInput,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.newDomainInput = $$v
                                                                },
                                                                expression:
                                                                  "newDomainInput"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-row",
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12"
                                                            }
                                                          },
                                                          [
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                label: "Reason",
                                                                maxlength: "50",
                                                                rules: [
                                                                  _vm.rules
                                                                    .required
                                                                ]
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.newDomainReason,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.newDomainReason = $$v
                                                                },
                                                                expression:
                                                                  "newDomainReason"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-card-actions",
                                              [
                                                _c("v-spacer"),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      color: "secondary"
                                                    },
                                                    on: { click: _vm.close }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        Cancel\n                      "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      color: "primary",
                                                      disabled:
                                                        !_vm.newDomainValid ||
                                                        _vm.isAddingDomain ||
                                                        !!_vm.addDomainError,
                                                      loading:
                                                        _vm.isAddingDomain
                                                    },
                                                    on: { click: _vm.save }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        Save\n                      "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "item.actions",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  loading:
                                    _vm.deleteingDomain === item.domain &&
                                    _vm.isDeletingDomain,
                                  disabled: _vm.isDeletingDomain,
                                  fab: "",
                                  icon: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.deleteItem(item)
                                  }
                                }
                              },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v(
                                    "\n                mdi-delete\n              "
                                  )
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }