<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card-title>Domain Omit Management</v-card-title>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="ml-2">
          <v-data-table
            :headers="headers"
            :items="domains"
            disable-pagination
            hide-default-footer
          >
            <template v-slot:top>
              <v-toolbar
                flat
              >
                <v-toolbar-title>Domains</v-toolbar-title>
                <v-divider
                  class="mx-4"
                  inset
                  vertical
                />
                <v-form
                  v-model="domainSearchValidation"
                  @submit.prevent="searchDomainOmits"
                >
                  <v-row>
                    <v-col
                      align-self="center"
                      cols="11"
                    >
                      <v-text-field
                        v-model="domainSearch"
                        dense
                        clearable
                        single-line
                        hide-details
                        :loading="isLoadingDomains"
                        label="Domain"
                        maxlength="50"
                        :rules="[rules.required, rules.validDomain]"
                      />
                    </v-col>
                    <v-col cols="1">
                      <v-btn
                        class="ma-2"
                        color="primary"
                        type="submit"
                        :disabled="!domainSearchValidation"
                      >
                        <v-icon dark>
                          mdi-magnify
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
                <v-spacer />
                <v-dialog
                  v-model="dialog"
                  max-width="500px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Add Domain
                    </v-btn>
                  </template>
                  <v-form v-model="newDomainValid">
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">Add Domain</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12">
                              <v-text-field
                                v-model="newDomainInput"
                                label="New Domain"
                                maxlength="50"
                                :rules="[rules.required, rules.validDomain]"
                                :hint="addDomainError"
                                :persistent-hint="!!addDomainError"
                              />
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12">
                              <v-text-field
                                v-model="newDomainReason"
                                label="Reason"
                                maxlength="50"
                                :rules="[rules.required]"
                              />
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="secondary"
                          @click="close"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="primary"
                          :disabled="!newDomainValid || isAddingDomain || !!addDomainError"
                          :loading="isAddingDomain"
                          @click="save"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn
                :loading="deleteingDomain === item.domain && isDeletingDomain"
                :disabled="isDeletingDomain"
                fab
                icon
                @click="deleteItem(item)"
              >
                <v-icon small>
                  mdi-delete
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatDate } from '@/shared/utils'
import { emailMasterService } from '../../../shared/services'

export default {
  name: 'DomainManagement',

  data () {
    return {
      isDeletingDomain: false,
      deleteingDomain: '',
      isLoadingDomains: false,
      domainSearch: '',
      domainSearchResults: [],
      addDomainError: '',
      domainSearchValidation: false,
      isAddingDomain: false,
      newDomainValid: false,
      newDomainInput: '',
      newDomainReason: '',
      dialog: false,
      headers: [
        { text: 'Domain', value: 'domain', align: 'start', sortable: true },
        { text: 'AddDate', value: 'addDate', align: 'start', sortable: true },
        { text: 'Operator', value: 'addOperator', align: 'start', sortable: true },
        { text: 'Omit Reason', value: 'omitReason', align: 'start', sortable: true },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      rules: {
        required: (v) => !!v || 'Required.',
        validDomain: (v) => !!v.match(/[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/) || 'Invalid domain'
      },
    }
  },
  computed: {
    ...mapGetters(['viewAsCustomer', 'simulatedCustomerCrmId']),
    ...mapGetters('auth', ['isMMSRole', 'getTenantId', 'getTenantCrmId']),
    domains () {
      return this.domainSearchResults.map(result => ({
        ...result,
        addDate: formatDate(result.addDate)
      }))
    }
  },
  watch: {
    newDomainInput () {
      if (this.addDomainError !== '') {
        this.addDomainError = ''
      }
    }
  },
  methods: {
    async searchDomainOmits() {
      this.isLoadingDomains = true

      const res = await emailMasterService.searchDomainOmits(this.domainSearch)

      if (res.errorResponse) {
        this.$store.commit('setGlobalSnackbar', {
          message: res.errorResponse,
          color: 'error'
        })
      } else {
        this.domainSearchResults = res
      }

      this.isLoadingDomains = false
    },
    async deleteItem(item) {
      this.isDeletingDomain = true
      this.deleteingDomain = item.domain
      const res = await emailMasterService.deleteDomain(item.domain)

      if (res && res.errorResponse) {
        // toast error
        this.$store.commit('setGlobalSnackbar', {
          message: res.errorResponse,
          color: 'error'
        })
      } else {
        // toast success
        this.$store.commit('setGlobalSnackbar', {
          message: `The ${item.domain} domain has been deleted`,
          color: 'success'
        })
         // refetch data
        this.searchDomainOmits()
      }
      this.deleteingDomain = ''
      this.isDeletingDomain = false
    },
    async addDomain (newDomain, reason) {
      this.isAddingDomain = true

      const res = await emailMasterService.addDomain(newDomain, reason)
      this.isAddingDomain = false
      if (res.errorResponse) {
        this.$store.commit('setGlobalSnackbar', {
          message: res.errorResponse,
          color: 'error'
        })
        return ''
      } else if (Array.isArray(res) && res.length > 0) {
        if (res.includes(`Domain: ${newDomain} Added`)) {
          this.$store.commit('setGlobalSnackbar', {
            message: `Domain: ${newDomain} Added`,
            color: 'success'
          })
          return ''
        }

        return res[0]
      }

      return ''
    },
    async save () {
      const result = await this.addDomain(this.newDomainInput, this.newDomainReason)
      if (result === '') {
        this.dialog = false
        this.newDomainInput = ''
        this.newDomainReason = ''
      }

      this.addDomainError = result
    },
    close () {
      this.dialog = false
      this.newDomainInput = ''
      this.newDomainReason = ''
      this.addDomainError = ''
    }
  }
}
</script>
